





































import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class MyDealerSites extends Vue {
  public loading: boolean = false;
  public search: string = '';

  public get headers(): any[] {
    return [
      {
        text: this.$t('colAccountNumber'),
        align: 'start',
        sortable: !this.$vuetify.breakpoint.xsOnly,
        value: 'accountNumber',
      },
      {
        text: this.$vuetify.breakpoint.xsOnly ? this.$t('colFlags') : ' ',
        align: 'start',
        sortable: false,
        value: 'flags',
      },
      {
        text: this.$t('colName'),
        align: 'start',
        sortable: !this.$vuetify.breakpoint.xsOnly,
        value: 'name',
      },
      {
        text: this.$t('colCity'),
        align: 'start',
        sortable: !this.$vuetify.breakpoint.xsOnly,
        value: 'city',
      },
    ];
  }

  public beforeMount(): void {
    this.search = this.$store.state.sitesFilter;
  }

  public async mounted(): Promise<void> {
    if (this.$store.state.dealers.length === 0) {
      await this.searchChanged();
    }
  }

  public async searchChanged(): Promise<void> {
    if (this.search === null) this.search = '';
    this.loading = true;
    await this.$store.dispatch('doFetchSites', this.search);
    this.loading = false;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public goToSite(item: any): void {
    this.$router.push({ name: 'site', params: { id: item.accountNumber } });
  }
}
