































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class Dealer extends Vue {
  public spinner: boolean = false;
  public loading: boolean = false;
  public tab: number = 1;
  public changePasswordDialog: boolean = false;
  public changePasswordDialogCurrentPassword: string = '';
  public changePasswordDialogNewPassword: string = '';
  public changePasswordDialogNewPasswordVerification: string = '';

  public search: string = '';

  public get headersSites(): any[] {
    return [
      {
        text: this.$t('colAccountNumber'),
        align: 'start',
        sortable: !this.$vuetify.breakpoint.xsOnly,
        value: 'accountNumber',
      },
      {
        text: this.$vuetify.breakpoint.xsOnly ? this.$t('colFlags') : ' ',
        align: 'start',
        sortable: false,
        value: 'flags',
      },
      {
        text: this.$t('colName'),
        align: 'start',
        sortable: !this.$vuetify.breakpoint.xsOnly,
        value: 'name',
      },
      {
        text: this.$t('colCity'),
        align: 'start',
        sortable: !this.$vuetify.breakpoint.xsOnly,
        value: 'city',
      },

    ];
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public goToSite(item: any): void {
    this.$router.push({ name: 'site', params: { id: item.accountNumber } });
  }

  @Watch('$route', { immediate: true, deep: true })
  public async ensureDealer(): Promise<void> {
    if (this.$route.name !== 'dealer') {
      return;
    }
    const dealerNumber = this.$route.params.id;
    this.loading = true;
    await this.$store.dispatch('doFetchCurrentDealer', dealerNumber);
    this.search = '';
    this.tab = 1;
    this.loading = false;
    this.changePasswordDialogCurrentPassword = '';
    this.changePasswordDialogNewPassword = '';
    this.changePasswordDialogNewPasswordVerification = '';
  }

  public openChangePasswordDialog(): void {
    this.changePasswordDialogCurrentPassword = '';
    this.changePasswordDialogNewPassword = '';
    this.changePasswordDialogNewPasswordVerification = '';
    this.changePasswordDialog = true;
  }
  public async changePasswordDialogSubmit(): Promise<void> {
    if (this.changePasswordDialogCurrentPassword === '') return;
    if (this.changePasswordDialogNewPassword !== this.changePasswordDialogNewPasswordVerification) return;
    if (this.changePasswordDialogNewPassword === '') return;
    const accountNumber = this.$store.state.currentDealer.dealer.accountNumber;
    const originatorAccountNumber = this.$store.state.signedInUserName;
    this.spinner = true;
    const result = await Vue.axios.post(`/dealers/changePassword?originatorAccountNumber=${originatorAccountNumber}&accountNumber=${accountNumber}`,
      {
        currentPassword: this.changePasswordDialogCurrentPassword,
        newPassword: this.changePasswordDialogNewPassword,
      });
    this.spinner = false;
    if (result.status !== 200) {
      Vue.toasted.error(this.$t('serverError') as string);
      return;
    }

    this.changePasswordDialogCurrentPassword = '';
    this.changePasswordDialogNewPassword = '';
    this.changePasswordDialogNewPasswordVerification = '';

    Vue.toasted.success(this.$t('serverSuccess') as string);
    this.changePasswordDialog = false;
  }
}
