import { User } from 'oidc-client';
import Vue from 'vue';
import Vuex from 'vuex';
import AuthService from '@/AuthService';
import * as Cookie from 'tiny-cookie';

let themeName = Cookie.get('theme-name');
if (themeName === null) themeName = 'light';

Vue.use(Vuex);

// class SiteDetail {
//   site: SiteComplex;
//   zones: Zone[];
//   traffic: TrafficMessage[];
//   modificationHistory: ModificationHistoryMessage[];
//   schedule: Schedule;
//   temporaryCallList: TemporaryCallList;
//   callLists: CallList[];
// }

// class CallList {
//   items: CallListItem[];
// }

// class TemporaryCallList {
//   items: CallListItem[];
//   reason: string;
// }

// class CallListItem {
//   userNumber: string;
//   password: string;
//   contactName: string;
//   contactTelephone: string;
//   additionalInformation: string;
// }

// class Schedule {
//   dayMonday: string;
//   dayTuesday: string;
//   dayWednesday: string;
//   dayThursday: string;
//   dayFriday: string;
//   daySaturday: string;
//   daySunday: string;

//   failToOpenDayMonday: string;
//   failToOpenDayTuesday: string;
//   failToOpenDayWednesday: string;
//   failToOpenDayThursday: string;
//   failToOpenDayFriday: string;
//   failToOpenDaySaturday: string;
//   failToOpenDaySunday: string;

//   earlyOpen: string;
//   lateOpen: string;
//   lateClose: string;
// }

// class ModificationHistoryMessage {
//   message: string;
//   dateTimeUTC: string; //watch as it is nullable
//   dateTimeUTCDate: Date;
//   dateTimeShowDay: string;
//   dateTimeShowDate: string;
//   dateTimeShowTime: string;
//   dateTimeShowHeader: boolean;
//   signal: string;
// }

// class TrafficMessage {
//   message: string;
//   dateTimeUTC: string; //watch as it is nullable
//   dateTimeUTCDate: Date;
//   dateTimeShowDay: string;
//   dateTimeShowDate: string;
//   dateTimeShowTime: string;
//   dateTimeShowHeader: boolean;
//   signal: string;
// }

// class Zone {
//   number: number;
//   code: string;
//   type: string;
//   description: string;
//   email: string;
//   isInTest: boolean;
//   callListNumber: number; //watch as it is nullable
// }

// class SiteComplex {
//   accountNumber: string;
//   name: string;
//   city: string;
//   dealerAccountNumber: string;
//   isInTest: boolean;
//   isDeleted: boolean;

//   dealerName: string;
//   address: string;
//   postalCode: string;
//   telephones: string[];
//   emails: string[];
//   timeZone: string;
// }

// class WebAuditLog {
//   accountNumber: string;
//   items: WebAuditLogItem[];
// }

// class WebAuditLogItem {
//   actionDateTimeUTC: string;
//   actionDateTimeUTCDate: Date;
//   dateTimeShowDay: string;
//   dateTimeShowDate: string;
//   dateTimeShowTime: string;
//   dateTimeShowHeader: boolean;
//   userHostAddress: string;
//   action: string;
// }

// class DealerSimple {
//   accountNumber: string = '';
//   name: string = '';
//   city: string = '';
//   isDeleted: boolean = false;
// }

// class SiteSimple {
//   accountNumber: string = '';
//   name: string = '';
//   city: string = '';
//   dealerAccountNumber: string = '';
//   isDeleted: boolean = false;
//   isInTest: boolean = false;
// }

// class DealerComplex {
//   accountNumber: string = '';
//   name: string = '';
//   city: string = '';
//   isDeleted: boolean = false;

//   address: string = '';
//   postalCode: string = '';
//   telephones: string[] = [];
//   emails: string[] = [];
// }

// class DealerDetail {
//   dealer: DealerComplex = new DealerComplex;
//   sites: SiteSimple[] = [];
// }

export default new Vuex.Store({
  state: {
    signedIn: false,
    signedInUserName: '',
    locale: 'en',
    buildNumber: process.env.VUE_APP_DEVOPS_BUILDNUMBER || 'dev',
    buildId: process.env.VUE_APP_DEVOPS_BUILDID || 'dev',
    roles: [],
    dealerLogoUrl: null,
    sites: [],
    sitesFilter: '',
    dealers: [],
    dealersFilter: '',
    currentDealer: null,
    currentSite: null,
    theme: themeName,
  },
  mutations: {
    changeLocale(state, newLocale: string) {
      state.locale = newLocale;
    },
    signIn(state, payload) {
      state.signedIn = true;
      state.signedInUserName = payload.userName;
      state.roles = [];

      state.sites = [];
      state.sitesFilter = '';
      state.dealers = [];
      state.dealersFilter = '';
      state.currentDealer = null;
      state.currentSite = null;
    },
    signOut(state) {
      state.signedIn = false;
      state.signedInUserName = '';
      state.roles = [];

      state.sites = [];
      state.sitesFilter = '';
      state.dealers = [];
      state.dealersFilter = '';
      state.currentDealer = null;
      state.currentSite = null;
      
      Vue.axios.defaults.headers.common = { Authorization: '' };
    },
    setRoles(state, payload) {
      state.roles = payload.roles;
    },
    setDealerLogoUrl(state, payload) {
      state.dealerLogoUrl = payload.dealerLogoUrl;
    },
    setDealers(state, payload) {
      state.dealersFilter = payload.filter;
      state.dealers = payload.data;
    },
    setSites(state, payload) {
      state.sitesFilter = payload.filter;
      state.sites = payload.data;
    },
    setCurrentDealer(state, payload) {
      state.currentDealer = payload.data;
    },
    setCurrentSite(state, payload) {
      state.currentSite = payload.data;
    },
  },
  getters: {
    isInRoleLANVAC: state => {
      const result = state.roles.find(t => t === 'LANVAC');
      return result !== undefined;
    },
    isInRoleDEALER: state => {
      const result = state.roles.find(t => t === 'DEALER');
      return result !== undefined;
    },
    isInRoleCLIENT: state => {
      const result = state.roles.find(t => t === 'CLIENT');
      return result !== undefined;
    },
  },
  actions: {
    async doSignIn(context, payload: User) {
      context.commit('signIn', {
        userName: payload.profile.name?.substr(0, payload.profile.name?.indexOf('-')),
      });

      Vue.axios.defaults.headers.common = { Authorization: `bearer ${await AuthService.getAccessToken()}` };

      const userInfo = await Vue.axios.get('/User/GetUserInfo');
      context.commit('setRoles', {
        roles: userInfo.data.roles,
      });
      context.commit('setDealerLogoUrl', {
        dealerLogoUrl: userInfo.data.dealerLogoUrl,
      });
    },
    async doFetchDealers(context, payload: string) {
      let filter = context.state.dealersFilter;
      if (payload !== undefined) {
        filter = payload;
      }

      const result = await Vue.axios.get(`/dealers/getAll?filter=${filter}`);
      context.commit('setDealers', {
        filter: filter,
        data: result.data,
      });
    },
    async doFetchSites(context, payload: string) {
      let filter = context.state.dealersFilter;
      if (payload !== undefined) {
        filter = payload;
      }

      const result = await Vue.axios.get(`/sites/getAll?filter=${filter}`);
      context.commit('setSites', {
        filter: filter,
        data: result.data,
      });
    },
    async doFetchCurrentDealer(context, payload: string) {
      const result = await Vue.axios.get(`/dealers/get?accountNumber=${payload}`);
      context.commit('setCurrentDealer', {
        data: result.data,
      });
    },
    async doFetchCurrentSite(context, payload: string) {
      const result = await Vue.axios.get(`/sites/get?accountNumber=${payload}`);
      context.commit('setCurrentSite', {
        data: result.data,
      });
    },
  },
  modules: {
  },
});
