





























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Component, Watch, Vue } from 'vue-property-decorator';
import moment from 'moment-timezone';

@Component({})
export default class Site extends Vue {
  public spinner: boolean = false;
  public loading: boolean = false;
  public tab: number = 0;
  public expandedCallLists: number[] = [];
  public traffic = [];
  public audit = [];
  public trafficDate: string = new Date().toISOString().substr(0, 10);
  public auditDate: string = new Date().toISOString().substr(0, 10);
  public trafficModal: boolean = false;
  public auditModal: boolean = false;
  public trafficToggleRecent: number = 0;
  public auditToggleRecent: number = 0;
  public toggleTimeZone: number = 0;
  public callListExpansions: any = {};
  public tempCallListExpansions: any[] = [];

  public requestServiceDialog: boolean = false;
  public changePasswordDialog: boolean = false;
  public requestEventsReportDialog: boolean = false;
  public requestModificationsReportDialog: boolean = false;
  public manageOnTestDialog: boolean = false;
  public editPhoneNumberDialog: boolean = false;
  public confirmCallListLineDeleteDialog: boolean = false;
  public editCallListLineDialog: boolean = false;

  public editPhoneNumberDialogPhoneNumber: string = '';
  public editPhoneNumberDialogPosition: number = 0;
  public changePasswordDialogCurrentPassword: string = '';
  public changePasswordDialogNewPassword: string = '';
  public changePasswordDialogNewPasswordVerification: string = '';
  public requestServiceDialogContextID: string = '';
  public requestServiceDialogContexts: string[] = [];
  public requestServiceDialogContext: string = '';
  public requestServiceDialogRequest: string = '';
  public requestModificationsReportDialogEmail: string = '';
  public requestModificationsReportDialogLanguage: string = 'English';
  public requestEventsReportDialogEmail: string = '';
  public requestEventsReportDialogLanguage: string = 'English';
  public requestEventsReportDialogFrom: string = new Date().toISOString().substr(0, 10);
  public requestEventsReportDialogTo: string = new Date().toISOString().substr(0, 10);
  public requestEventsReportDialogFromModal: boolean = false;
  public requestEventsReportDialogToModal: boolean = false;
  public manageOnTestDialogOnTestSite: boolean = false;
  public manageOnTestDialogShowZones: boolean = false;
  // eslint-disable-next-line @typescript-eslint/ban-types
  public manageOnTestDialogOnTestZones: any = {};
  public manageOnTestDialogDurations: string[] = [];
  public manageOnTestDialogDuration: string = '';
  public manageOnTestDialogDatePickerMenu: boolean = false;
  public manageOnTestDialogDatePickerDate: string = '';
  public manageOnTestDialogTimePickerMenu: boolean = false;
  public manageOnTestDialogTimePickerTime: string = '';
  public confirmCallListLineDeleteDialogItem: any = {};
  public editCallListLineDialogItem: any = {};

  public confirmEmergencyDialog: boolean = false;
  public confirmEmergencyDialogContext: string = '';
  public confirmEmergencyDialogGPS: any | null = null;

  public canAddLineAfter(item: any, callList: number): boolean {
    if (item.canEdit === true) return true;

    const index = this.$store.state.currentSite.callLists[callList].items.indexOf(item);
    const itemAfter = this.$store.state.currentSite.callLists[callList].items[index + 1];

    if (itemAfter === undefined || itemAfter === null) return true;
    return itemAfter.canEdit;
  }

  public get manageOnTestDialogDatePickerMin(): string {
    const dt = new Date();
    return dt.toISOString().substr(0, 10);
  }

  public get manageOnTestDialogDatePickerMax(): string {
    const dt = new Date();
    dt.setDate(dt.getDate() + 120);
    return dt.toISOString().substr(0, 10);
  }

  @Watch('trafficDate', { immediate: false, deep: false })
  public async trafficDateChanged(): Promise<void> {
    if (this.trafficToggleRecent === 1) await this.showFilteredTraffic();
  }

  @Watch('auditDate', { immediate: false, deep: false })
  public async auditDateChanged(): Promise<void> {
    if (this.auditToggleRecent === 1) await this.showFilteredAudit();
  }

  @Watch('$route', { immediate: true, deep: true })
  public async ensureSite(): Promise<void> {
    if (this.$route.name !== 'site') {
      return;
    }

    const siteNumber = this.$route.params.id;
    this.loading = true;
    this.expandedCallLists = [];
    await this.$store.dispatch('doFetchCurrentSite', siteNumber);
    // this.tab = 0;
    this.traffic = this.$store.state.currentSite.traffic;
    this.audit = this.$store.state.currentSite.modificationHistory;
    this.trafficDate = new Date().toISOString().substr(0, 10);
    this.auditDate = new Date().toISOString().substr(0, 10);
    this.trafficToggleRecent = 0;
    this.auditToggleRecent = 0;
    this.toggleTimeZone = 0;
    this.changePasswordDialogCurrentPassword = '';
    this.changePasswordDialogNewPassword = '';
    this.changePasswordDialogNewPasswordVerification = '';
    this.requestServiceDialogContextID = '';
    this.requestServiceDialogContexts = [];
    this.requestServiceDialogContext = '';
    this.requestServiceDialogRequest = '';
    this.requestModificationsReportDialogEmail = '';
    this.requestModificationsReportDialogLanguage = 'English';
    this.requestEventsReportDialogEmail = '';
    this.requestEventsReportDialogLanguage = 'English';
    this.manageOnTestDialogOnTestSite = false;
    this.manageOnTestDialogShowZones = false;
    this.manageOnTestDialogOnTestZones = {};
    this.manageOnTestDialogDurations = [];
    this.manageOnTestDialogDuration = '';
    this.editPhoneNumberDialogPhoneNumber = '';
    this.editPhoneNumberDialogPosition = 0;
    this.confirmCallListLineDeleteDialogItem = {};
    this.editCallListLineDialogItem = {};

    let o = 0;
    this.callListExpansions = {};
    this.$store.state.currentSite.callLists.forEach(() => {
      Vue.set(this.callListExpansions, o, []);
      o++;
    });
    this.tempCallListExpansions = [];

    // expand all call list tabs in fullscreen
    let i = 0;
    if (this.$vuetify.breakpoint.mdAndUp && (this.$store.state.currentSite.temporaryCallList !== null || this.$store.state.currentSite.temporaryCallList !== undefined)) {
      this.expandedCallLists.push(i);
      i++;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.$store.state.currentSite.callLists.forEach(() => {
      if (this.$vuetify.breakpoint.mdAndUp) this.expandedCallLists.push(i);
      i++;
    });
    if (this.$vuetify.breakpoint.mdAndUp && (this.$store.state.currentSite.temporaryCallList === null || this.$store.state.currentSite.temporaryCallList === undefined)) {
      this.expandedCallLists.push(i);
      i++;
    }

    this.loading = false;
  }

  public goToCallList(callListNumber: number): void {
    this.tab = 3;

    this.expandedCallLists = [];
    if (this.$store.state.currentSite.temporaryCallList !== null && this.$store.state.currentSite.temporaryCallList !== undefined) {
      this.expandedCallLists.push(callListNumber);
    } else {
      this.expandedCallLists.push(callListNumber - 1);
    }
  }

  public getDisplay(dateString: string): string {
    if (dateString === null) return '';
    return moment(new Date(dateString)).format('yyyy-MM-DD HH:mm');
  }

  public showRecentTraffic(): void {
    this.traffic = this.$store.state.currentSite.traffic;
  }

  public showRecentAudit(): void {
    this.audit = this.$store.state.currentSite.modificationHistory;
  }

  public addCallListLine(callListNumber: number): void {
    this.editCallListLineDialogItem = {
      callListItemNumber: -999,
      callListNumber: callListNumber,
      userNumber: '',
      password: '',
      contactName: '',
      contactTelephone: '',
      additionalInformation: '',
      specialNote: '',
      showEveryDay: true,
      showMonday: true,
      showTuesday: true,
      showWednesday: true,
      showThursday: true,
      showFriday: true,
      showSaturday: true,
      showSunday: true,
      showAllDay: true,
      showStart: '00:00',
      showEnd: '00:00',
      extraLines: 0,
    };

    this.editCallListLineDialog = true;
  }
  public addCallListLineAfter(item: any, callListNumber: number): void {
    this.editCallListLineDialogItem = {
      callListItemNumber: item.number,
      callListNumber: callListNumber,
      userNumber: '',
      password: '',
      contactName: '',
      contactTelephone: '',
      additionalInformation: '',
      specialNote: '',
      showEveryDay: true,
      showMonday: true,
      showTuesday: true,
      showWednesday: true,
      showThursday: true,
      showFriday: true,
      showSaturday: true,
      showSunday: true,
      showAllDay: true,
      showStart: '00:00',
      showEnd: '00:00',
      extraLines: 0,
      afterItem: item,
    };

    this.editCallListLineDialog = true;
  }
  public editCallListLine(item: any, callListNumber: number): void {
    this.editCallListLineDialogItem = {
      callListItemNumber: item.number,
      callListNumber: callListNumber,
      oldItem: item,
      userNumber: item.userNumber,
      password: item.password,
      contactName: item.contactName,
      contactTelephone: item.contactTelephone,
      additionalInformation: item.additionalInformation,
      specialNote: item.specialNote,
      showEveryDay: item.showEveryDay,
      showMonday: item.showMonday,
      showTuesday: item.showTuesday,
      showWednesday: item.showWednesday,
      showThursday: item.showThursday,
      showFriday: item.showFriday,
      showSaturday: item.showSaturday,
      showSunday: item.showSunday,
      showAllDay: item.showAllDay,
      showStart: (item.showStartHour ? item.showStartHour : 0).toString().padStart(2, '0') + ':' + (item.showStartMinute ? item.showStartMinute : 0).toString().padStart(2, '0'),
      showEnd: (item.showEndHour ? item.showEndHour : 0).toString().padStart(2, '0') + ':' + (item.showEndMinute ? item.showEndMinute : 0).toString().padStart(2, '0'),
      extraLines: 0,
    };

    this.editCallListLineDialog = true;
  }
  public deleteCallListLine(item: any, callListNumber: number): void {
    this.confirmCallListLineDeleteDialogItem = {
      callListItemNumber: item.number,
      callListNumber: callListNumber,
      item: item,
      extraLines: 0,
    };

    this.confirmCallListLineDeleteDialog = true;
  }
  public async confirmCallListLineDeleteDialogSubmit(): Promise<void> {
    const accountNumber = this.$store.state.currentSite.site.accountNumber;
    this.spinner = true;
    const result = await Vue.axios.post(`/sites/removeCallListItem?accountNumber=${accountNumber}`,
      {
        callListItemNumber: this.confirmCallListLineDeleteDialogItem.callListItemNumber,
        callListNumber: this.confirmCallListLineDeleteDialogItem.callListNumber,
        callListItem: this.confirmCallListLineDeleteDialogItem.item,
        extraLines: this.confirmCallListLineDeleteDialogItem.extraLines,
      });
    this.spinner = false;
    if (result.status === 409) {
      Vue.toasted.error(this.$t('serverErrorConcurrency') as string);
      return;
    }
    if (result.status !== 200) {
      Vue.toasted.error(this.$t('serverError') as string);
      return;
    }

    Vue.toasted.success(this.$t('serverSuccess') as string);

    this.loading = true;
    Vue.toasted.info(this.$t('pleaseWait') as string, {
      duration: 6000,
    });
    window.setTimeout(async () => { this.ensureSite(); }, 5000);

    this.confirmCallListLineDeleteDialog = false;
  }
  public async editCallListLineDialogSubmit(): Promise<void> {

    // verify schedule rules
    if (this.editCallListLineDialogItem.showEveryDay === false &&
      this.editCallListLineDialogItem.showMonday === false &&
      this.editCallListLineDialogItem.showTuesday === false &&
      this.editCallListLineDialogItem.showWednesday === false &&
      this.editCallListLineDialogItem.showThursday === false &&
      this.editCallListLineDialogItem.showFriday === false &&
      this.editCallListLineDialogItem.showSaturday === false &&
      this.editCallListLineDialogItem.showSunday === false) {
      Vue.toasted.error(this.$t('editCallListLineDialogErrorScheduleEmpty') as string);
      return;
    }

    this.editCallListLineDialogItem.showStartHour = this.editCallListLineDialogItem.showStart.split(':')[0];
    this.editCallListLineDialogItem.showStartMinute = this.editCallListLineDialogItem.showStart.split(':')[1];
    this.editCallListLineDialogItem.showEndHour = this.editCallListLineDialogItem.showEnd.split(':')[0];
    this.editCallListLineDialogItem.showEndMinute = this.editCallListLineDialogItem.showEnd.split(':')[1];

    const accountNumber = this.$store.state.currentSite.site.accountNumber;
    let result: any = null;
    this.spinner = true;
    if (this.editCallListLineDialogItem.callListItemNumber === -999) {
      result = await Vue.axios.post(`/sites/addCallListItem?accountNumber=${accountNumber}`,
        {
          callListNumber: this.editCallListLineDialogItem.callListNumber,
          callListItem: this.editCallListLineDialogItem,
          extraLines: this.editCallListLineDialogItem.extraLines,
        });
    } else if (this.editCallListLineDialogItem.afterItem) {
      result = await Vue.axios.post(`/sites/addCallListItemAfter?accountNumber=${accountNumber}`,
        {
          callListItemNumber: this.editCallListLineDialogItem.callListItemNumber,
          callListNumber: this.editCallListLineDialogItem.callListNumber,
          callListItemAfter: this.editCallListLineDialogItem.afterItem,
          callListItemNew: this.editCallListLineDialogItem,
          extraLines: this.editCallListLineDialogItem.extraLines,
        });
    } else if (this.editCallListLineDialogItem.oldItem) {
      result = await Vue.axios.post(`/sites/updateCallListItem?accountNumber=${accountNumber}`,
        {
          callListItemNumber: this.editCallListLineDialogItem.callListItemNumber,
          callListNumber: this.editCallListLineDialogItem.callListNumber,
          callListItemOld: this.editCallListLineDialogItem.oldItem,
          callListItemNew: this.editCallListLineDialogItem,
          extraLines: this.editCallListLineDialogItem.extraLines,
        });
    }
    this.spinner = false;
    if (result.status === 409) {
      Vue.toasted.error(this.$t('serverErrorConcurrency') as string);
      return;
    }
    if (result.status !== 200) {
      Vue.toasted.error(this.$t('serverError') as string);
      return;
    }

    Vue.toasted.success(this.$t('serverSuccess') as string);

    this.loading = true;
    Vue.toasted.info(this.$t('pleaseWait') as string, {
      duration: 6000,
    });
    window.setTimeout(async () => { this.ensureSite(); }, 5000);

    this.editCallListLineDialog = false;
  }

  public openEditPhoneNumberDialog(position: number): void {
    this.editPhoneNumberDialogPosition = position;

    this.editPhoneNumberDialogPhoneNumber = '';
    if (this.$store.state.currentSite.site.telephones[position] !== undefined) {
      this.editPhoneNumberDialogPhoneNumber = this.$store.state.currentSite.site.telephones[position];
    }

    this.editPhoneNumberDialog = true;
  }
  public async openRequestServiceDialog(contextID: string): Promise<void> {
    const result = await Vue.axios.get('/sites/dealerServiceCallContextList');
    if (result.status !== 200) {
      Vue.toasted.error(this.$t('serverError') as string);
      return;
    }

    this.requestServiceDialogContexts = result.data[this.$store.state.locale];
    this.requestServiceDialogContextID = contextID;

    if (this.$store.state.locale === 'fr') this.requestServiceDialogContext = 'Autre';
    else this.requestServiceDialogContext = 'Other';

    this.requestServiceDialogRequest = '';
    this.requestServiceDialog = true;
  }
  public openChangePasswordDialog(): void {
    this.changePasswordDialogCurrentPassword = '';
    this.changePasswordDialogNewPassword = '';
    this.changePasswordDialogNewPasswordVerification = '';
    this.changePasswordDialog = true;
  }
  public openRequestEventsReportDialog(): void {
    this.requestEventsReportDialogEmail = '';
    if (this.$store.state.currentSite.site.emails.length > 0) {
      this.requestEventsReportDialogEmail = this.$store.state.currentSite.site.emails[0];
    }
    this.requestEventsReportDialogLanguage = 'English';
    const dt = new Date();
    // dt.setDate(dt.getDate() - 7);
    this.requestEventsReportDialogFrom = dt.toISOString().substr(0, 10);
    this.requestEventsReportDialogTo = new Date().toISOString().substr(0, 10);
    this.requestEventsReportDialog = true;
  }
  public openRequestModificationsReportDialog(): void {
    this.requestModificationsReportDialog = true;
    this.requestModificationsReportDialogEmail = '';
    this.requestModificationsReportDialogLanguage = 'English';
  }
  public openManageOnTestDialog(): void {
    this.manageOnTestDialogOnTestSite = this.$store.state.currentSite.site.isInTest;
    this.manageOnTestDialogShowZones = false;
    this.manageOnTestDialogOnTestZones = {};
    this.$store.state.currentSite.zones.forEach((element: any) => {
      this.manageOnTestDialogOnTestZones[`zone${element.number.toString()}`] = element.isInTest;
    });
    this.manageOnTestDialogDurations = [
      this.$t('manageOnTestDialogDurations30m') as string,
      this.$t('manageOnTestDialogDurations1h') as string,
      this.$t('manageOnTestDialogDurations2h') as string,
      this.$t('manageOnTestDialogDurations3h') as string,
      this.$t('manageOnTestDialogDurations4h') as string,
      this.$t('manageOnTestDialogDurations5h') as string,
      this.$t('manageOnTestDialogDurations6h') as string,
      this.$t('manageOnTestDialogDurationsOther') as string,
    ];
    this.manageOnTestDialogDuration = this.manageOnTestDialogDurations[0];
    const dt = new Date();
    dt.setHours(dt.getHours() + 1);
    this.manageOnTestDialogDatePickerDate = `${dt.getFullYear()}-${dt.getMonth() + 1}-${dt.getDate()}`;
    this.manageOnTestDialogTimePickerTime = `${dt.getHours()}:${dt.getMinutes()}`;
    this.manageOnTestDialog = true;
  }
  public async requestServiceDialogSubmit(): Promise<void> {
    if (this.requestServiceDialogContextID === '') return;
    if (this.requestServiceDialogContext === '') return;
    if (this.requestServiceDialogRequest === '') return;

    const accountNumber = this.$store.state.currentSite.site.accountNumber;
    this.spinner = true;
    const result = await Vue.axios.post(`/sites/requestService?accountNumber=${accountNumber}`,
      {
        contextId: this.requestServiceDialogContextID,
        context: this.requestServiceDialogContext,
        message: this.requestServiceDialogRequest,
      });
    this.spinner = false;
    if (result.status !== 200) {
      Vue.toasted.error(this.$t('serverError') as string);
      return;
    }

    Vue.toasted.success(this.$t('serverSuccess') as string);
    this.requestServiceDialog = false;
  }
  public async changePasswordDialogSubmit(): Promise<void> {
    if (this.changePasswordDialogNewPassword !== this.changePasswordDialogNewPasswordVerification) return;
    if (this.changePasswordDialogNewPassword === '') return;

    const accountNumber = this.$store.state.currentSite.site.accountNumber;
    const originatorAccountNumber = this.$store.state.signedInUserName;
    this.spinner = true;
    const result = await Vue.axios.post(`/sites/changePassword?originatorAccountNumber=${originatorAccountNumber}&accountNumber=${accountNumber}`,
      {
        currentPassword: this.changePasswordDialogCurrentPassword,
        newPassword: this.changePasswordDialogNewPassword,
      });
    this.spinner = false;
    if (result.status !== 200) {
      Vue.toasted.error(this.$t('serverError') as string);
      return;
    }

    this.changePasswordDialogCurrentPassword = '';
    this.changePasswordDialogNewPassword = '';
    this.changePasswordDialogNewPasswordVerification = '';

    Vue.toasted.success(this.$t('serverSuccess') as string);
    this.changePasswordDialog = false;
  }
  public async requestEventsReportDialogSubmit(): Promise<void> {
    const accountNumber = this.$store.state.currentSite.site.accountNumber;
    const fromString = this.requestEventsReportDialogFrom;
    const toString = this.requestEventsReportDialogTo;

    const dtFrom = new Date();
    dtFrom.setDate(dtFrom.getDate() - 31);
    if (new Date(fromString) < dtFrom || new Date(fromString) > new Date(toString)) {
      Vue.toasted.info(this.$t('requestEventsReportDialogDateFromInvalid') as string);
      return;
    }

    this.spinner = true;
    const result = await Vue.axios.post(`/sites/requestEventsByRangeReport?accountNumber=${accountNumber}&language=${this.requestEventsReportDialogLanguage}&from=${fromString}&to=${toString}&email=${this.requestEventsReportDialogEmail}`,
      {
      });
    this.spinner = false;
    if (result.status !== 200) {
      Vue.toasted.error(this.$t('serverError') as string);
      return;
    }

    Vue.toasted.success(this.$t('serverSuccess') as string);
    this.requestEventsReportDialog = false;
  }
  public async confirmEmergencyDialogSubmit(): Promise<void> {
    const accountNumber = this.$store.state.currentSite.site.accountNumber;
    let result;
    let latitude = '';
    let longitude = '';
    if (this.confirmEmergencyDialogGPS !== null) {
      latitude = this.confirmEmergencyDialogGPS.latitude.toString();
      longitude = this.confirmEmergencyDialogGPS.longitude.toString();
    }
    this.spinner = true;
    if (this.confirmEmergencyDialogContext === 'police') {
      result = await Vue.axios.post(`/sites/emergencyPolice?accountNumber=${accountNumber}`,
        {
          latitude,
          longitude,
        });
    } else if (this.confirmEmergencyDialogContext === 'ambulance') {
      result = await Vue.axios.post(`/sites/emergencyAmbulance?accountNumber=${accountNumber}`,
        {
          latitude,
          longitude,
        });
    } else if (this.confirmEmergencyDialogContext === 'fire') {
      result = await Vue.axios.post(`/sites/emergencyFire?accountNumber=${accountNumber}`,
        {
          latitude,
          longitude,
        });
    }
    this.spinner = false;
    if (result && result.status !== 200) {
      Vue.toasted.error(this.$t('serverError') as string);
      return;
    }

    Vue.toasted.success(this.$t('serverSuccess') as string);
    this.confirmEmergencyDialog = false;
  }
  public helpPolice(): void {
    this.confirmEmergencyDialog = true;
    this.confirmEmergencyDialogContext = 'police';
    navigator.geolocation.getCurrentPosition(
      (s) => { this.confirmEmergencyDialogGPS = s.coords; },
      () => { Vue.toasted.error(this.$t('gpsError') as string); }, {
        enableHighAccuracy: true,
        maximumAge: 30000,
        timeout: 15000,
      });
  }
  public helpAmbulance(): void {
    this.confirmEmergencyDialog = true;
    this.confirmEmergencyDialogContext = 'ambulance';
    this.confirmEmergencyDialogGPS = null;
    navigator.geolocation.getCurrentPosition(
      (s) => { this.confirmEmergencyDialogGPS = s.coords; },
      () => { Vue.toasted.error(this.$t('gpsError') as string); }, {
        enableHighAccuracy: true,
        maximumAge: 30000,
        timeout: 15000,
      });
  }
  public helpFire(): void {
    this.confirmEmergencyDialog = true;
    this.confirmEmergencyDialogContext = 'fire';
    navigator.geolocation.getCurrentPosition(
      (s) => { this.confirmEmergencyDialogGPS = s.coords; },
      () => { Vue.toasted.error(this.$t('gpsError') as string); }, {
        enableHighAccuracy: true,
        maximumAge: 30000,
        timeout: 15000,
      });
  }
  public async requestModificationsReportDialogSubmit(): Promise<void> {
    const accountNumber = this.$store.state.currentSite.site.accountNumber;
    this.spinner = true;
    const result = await Vue.axios.post(`/sites/requestModificationsReport?accountNumber=${accountNumber}&language=${this.requestModificationsReportDialogLanguage}&email=${this.requestModificationsReportDialogEmail}`,
      {
      });
    this.spinner = false;
    if (result.status !== 200) {
      Vue.toasted.error(this.$t('serverError') as string);
      return;
    }

    Vue.toasted.success(this.$t('serverSuccess') as string);
    this.requestModificationsReportDialog = false;
  }
  public async editPhoneNumberDialogSubmit(): Promise<void> {
    const accountNumber = this.$store.state.currentSite.site.accountNumber;
    this.spinner = true;
    const result = await Vue.axios.post(`/sites/changePhoneNumber?accountNumber=${accountNumber}`,
      {
        recordNumber: this.editPhoneNumberDialogPosition,
        newPhoneNumber: this.editPhoneNumberDialogPhoneNumber,
      });
    this.spinner = false;
    if (result.status !== 200) {
      Vue.toasted.error(this.$t('serverError') as string);
      return;
    }

    Vue.toasted.success(this.$t('serverSuccess') as string);

    this.loading = true;
    Vue.toasted.info(this.$t('pleaseWait') as string, {
      duration: 6000,
    });
    window.setTimeout(async () => { this.ensureSite(); }, 5000);

    this.editPhoneNumberDialog = false;
  }
  public async manageOnTestDialogSubmit(): Promise<void> {
    const startTestDateTime = new Date();
    let endTestDateTime = new Date();
    if (this.manageOnTestDialogDuration === this.$t('manageOnTestDialogDurations30m')) endTestDateTime.setMinutes(endTestDateTime.getMinutes() + 30);
    else if (this.manageOnTestDialogDuration === this.$t('manageOnTestDialogDurations1h')) endTestDateTime.setHours(endTestDateTime.getHours() + 1);
    else if (this.manageOnTestDialogDuration === this.$t('manageOnTestDialogDurations2h')) endTestDateTime.setHours(endTestDateTime.getHours() + 2);
    else if (this.manageOnTestDialogDuration === this.$t('manageOnTestDialogDurations3h')) endTestDateTime.setHours(endTestDateTime.getHours() + 3);
    else if (this.manageOnTestDialogDuration === this.$t('manageOnTestDialogDurations4h')) endTestDateTime.setHours(endTestDateTime.getHours() + 4);
    else if (this.manageOnTestDialogDuration === this.$t('manageOnTestDialogDurations5h')) endTestDateTime.setHours(endTestDateTime.getHours() + 5);
    else if (this.manageOnTestDialogDuration === this.$t('manageOnTestDialogDurations6h')) endTestDateTime.setHours(endTestDateTime.getHours() + 6);
    else if (this.manageOnTestDialogDuration === this.$t('manageOnTestDialogDurationsOther')) {
      endTestDateTime = new Date(`${this.manageOnTestDialogDatePickerDate} ${this.manageOnTestDialogTimePickerTime}`);
    }

    const accountNumber = this.$store.state.currentSite.site.accountNumber;
    this.spinner = true;
    const result = await Vue.axios.post(`/sites/manageOnTest?accountNumber=${accountNumber}`,
      {
        startTestDateTime,
        endTestDateTime,
        site: this.manageOnTestDialogOnTestSite,
        ...this.manageOnTestDialogOnTestZones,
      });
    this.spinner = false;
    if (result.status !== 200) {
      Vue.toasted.error(this.$t('serverError') as string);
      return;
    }

    Vue.toasted.success(this.$t('serverSuccess') as string);

    this.loading = true;
    Vue.toasted.info(this.$t('pleaseWait') as string, {
      duration: 6000,
    });
    window.setTimeout(async () => { this.ensureSite(); }, 5000);

    this.manageOnTestDialog = false;
  }

  public async showFilteredTraffic(): Promise<void> {
    const accountNumber = this.$store.state.currentSite.site.accountNumber;
    const result = await Vue.axios.get(`/sites/GetTrafficFull?accountNumber=${accountNumber}&date=${this.trafficDate}`);
    this.traffic = result.data;
  }

  public async showFilteredAudit(): Promise<void> {
    const accountNumber = this.$store.state.currentSite.site.accountNumber;
    const result = await Vue.axios.get(`/sites/GetModificationHistoryFull?accountNumber=${accountNumber}&date=${this.auditDate}`);
    this.audit = result.data;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public getClassForTraffic(traffic: any): string {
    let cssClass = 'trafficDefault';

    if (traffic.message.indexOf('|') > -1) cssClass = 'trafficPipe';
    if (traffic.message.slice(0, 1) === '-') cssClass = 'trafficDash';
    if (traffic.message.slice(0, 2) === '<<') cssClass = 'trafficDoubleST';
    if (traffic.message.slice(0, 7) === 'RESTORE') cssClass = 'trafficRestore';
    if (traffic.message.indexOf('*DELAY') > -1) cssClass = 'trafficDelay';
    if (traffic.message.indexOf('LINE-G') > -1) cssClass = 'trafficLineG';
    if (traffic.message.indexOf('SIGNAL COMING FROM') > -1) cssClass = 'trafficSignal';
    if (traffic.message.indexOf('-OF-TRANSMITION') > -1) cssClass = 'trafficOfTransmission';
    if (traffic.message.indexOf('[E-MAIL]') > -1) cssClass = 'trafficEmail';
    if (traffic.message.indexOf('NORTEL') > -1) cssClass = 'trafficNortel';
    if (traffic.message.indexOf('STN =') > -1) cssClass = 'trafficSTN';
    if (traffic.message.indexOf('SIGNAL SOUS T') > -1) cssClass = 'trafficSignalSousT';
    if (traffic.message.indexOf('SIGNAL BEING H') > -1) cssClass = 'trafficSignalBeingH';

    if (traffic.message.length > 14) {
      if (traffic.message.slice(0, 2) === '<<' && (traffic.message.slice(9, 2) === '>>' || traffic.message.slice(8, 2) === '>>')) {
        cssClass = 'trafficDefault';
      }
    }

    // adjust for theme
    if (this.$store.state.theme === 'dark') cssClass += 'Dark';
    else cssClass += 'Light';

    return cssClass;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public dataScheduleIsHighlighted(row: any): string {
    if (row.dayOfWeek === new Date().getDay()) return 'grey';
    return '';
  }

  public getZoneTypeText(zoneType: string): string {
    if (zoneType === null || zoneType === undefined) return '';
    zoneType = zoneType.trim();
    zoneType = zoneType.replace('/', '');
    zoneType = zoneType.replace('*', '');
    if (zoneType.length === 0) return '';

    return (this.$t(`zoneType_${zoneType}`) as string);
  }

  public showDay(dateTimeUTC: string): string {
    if (dateTimeUTC === null) return '';
    const d = new Date(dateTimeUTC);

    let m = moment(d);
    if (this.toggleTimeZone === 1) m = moment(m).tz(this.$store.state.currentSite.site.timeZone);

    const daysOfWeek = ['weekDaySunday', 'weekDayMonday', 'weekDayTuesday', 'weekDayWednesday', 'weekDayThursday', 'weekDayFriday', 'weekDaySaturday'];
    return this.$t(daysOfWeek[m.day()]) as string;
  }
  public showDate(dateTimeUTC: string): string {
    if (dateTimeUTC === null) return '';
    const d = new Date(dateTimeUTC);

    let m = moment(d);
    if (this.toggleTimeZone === 1) m = moment(m).tz(this.$store.state.currentSite.site.timeZone);

    const months = ['monthJan', 'monthFeb', 'monthMar', 'monthApr', 'monthMay', 'monthJun', 'monthJul', 'monthAug', 'monthSep', 'monthOct', 'monthNov', 'monthDec'];
    return `${this.$t(months[m.month()])}-${m.date()}-${m.year()}`;
  }
  public showTime(dateTimeUTC: string): string {
    if (dateTimeUTC === null) return '';
    const d = new Date(dateTimeUTC);

    let m = moment(d);
    if (this.toggleTimeZone === 1) m = moment(m).tz(this.$store.state.currentSite.site.timeZone);

    return `${m.hours()}:${('00' + m.minutes().toString()).slice(-2)}:${('00' + m.seconds().toString()).slice(-2)}`;
  }

  public get dataSchedule(): any[] {
    const data = [];

    data.push({
      dayOfWeek: 1,
      name: this.$t('monday'),
      day: this.$store.state.currentSite.schedule.dayMonday,
      failToOpen: this.$store.state.currentSite.schedule.failToOpenDayMonday,
    });
    data.push({
      dayOfWeek: 2,
      name: this.$t('tuesday'),
      day: this.$store.state.currentSite.schedule.dayTuesday,
      failToOpen: this.$store.state.currentSite.schedule.failToOpenDayTuesday,
    });
    data.push({
      dayOfWeek: 3,
      name: this.$t('wednesday'),
      day: this.$store.state.currentSite.schedule.dayWednesday,
      failToOpen: this.$store.state.currentSite.schedule.failToOpenDayWednesday,
    });
    data.push({
      dayOfWeek: 4,
      name: this.$t('thursday'),
      day: this.$store.state.currentSite.schedule.dayThursday,
      failToOpen: this.$store.state.currentSite.schedule.failToOpenDayThursday,
    });
    data.push({
      dayOfWeek: 5,
      name: this.$t('friday'),
      day: this.$store.state.currentSite.schedule.dayFriday,
      failToOpen: this.$store.state.currentSite.schedule.failToOpenDayFriday,
    });
    data.push({
      dayOfWeek: 6,
      name: this.$t('saturday'),
      day: this.$store.state.currentSite.schedule.daySaturday,
      failToOpen: this.$store.state.currentSite.schedule.failToOpenDaySaturday,
    });
    data.push({
      dayOfWeek: 0,
      name: this.$t('sunday'),
      day: this.$store.state.currentSite.schedule.daySunday,
      failToOpen: this.$store.state.currentSite.schedule.failToOpenDaySunday,
    });

    return data;
  }

  public get headersSchedule(): any[] {
    return [
      {
        text: this.$t('colScheduleName'),
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: this.$t('colScheduleDay'),
        align: 'start',
        sortable: false,
        value: 'day',
      },
      {
        text: this.$t('colScheduleFailToOpen'),
        align: 'start',
        sortable: false,
        value: 'failToOpen',
      },
    ];
  }

  public get headersCallList(): any[] {
    if (this.$vuetify.breakpoint.xsOnly) {
      return [
        {
          text: this.$t('colCallListUserNumber'),
          align: 'start',
          sortable: false,
          value: 'userNumber',
        },
        {
          text: this.$t('colCallListPassword'),
          align: 'start',
          sortable: false,
          value: 'password',
        },
        {
          text: this.$t('colCallListContactName'),
          align: 'start',
          sortable: false,
          value: 'contactName',
        },
        {
          text: this.$t('colCallListContactTelephone'),
          align: 'start',
          sortable: false,
          value: 'contactTelephone',
        },
        {
          text: this.$t('colCallListAdditionalInformation'),
          align: 'start',
          sortable: false,
          value: 'additionalInformation',
        },
        {
          text: this.$t('colCallListSpecialNote'),
          align: 'start',
          sortable: false,
          value: 'specialNote',
        },
        {
          text: this.$t('callListSchedule'),
          align: 'start',
          sortable: false,
          value: 'scheduleMobile',
        },
        {
          text: this.$t('colCallListActions'),
          align: 'start',
          sortable: false,
          value: 'actions',
        },
      ];
    }
    return [
      {
        text: '',
        align: 'start',
        sortable: false,
        value: 'flags',
      },
      {
        text: this.$t('colCallListUserNumber'),
        align: 'start',
        sortable: false,
        value: 'userNumber',
      },
      {
        text: this.$t('colCallListPassword'),
        align: 'start',
        sortable: false,
        value: 'password',
      },
      {
        text: this.$t('colCallListContactName'),
        align: 'start',
        sortable: false,
        value: 'contactName',
      },
      {
        text: this.$t('colCallListContactTelephone'),
        align: 'start',
        sortable: false,
        value: 'contactTelephone',
      },
      {
        text: this.$t('colCallListAdditionalInformation'),
        align: 'start',
        sortable: false,
        value: 'additionalInformation',
      },
      {
        text: this.$t('colCallListActions'),
        align: 'start',
        sortable: false,
        value: 'actions',
      },
    ];
  }

  public get headersZone(): any[] {
    return [
      {
        text: this.$t('colZoneNumber'),
        align: 'start',
        sortable: false,
        value: 'number',
      },
      {
        text: this.$t('colZoneCode'),
        align: 'start',
        sortable: false,
        value: 'code',
      },
      {
        text: this.$t('colZoneType'),
        align: 'start',
        sortable: false,
        value: 'type',
      },
      {
        text: this.$t('colZoneDescription'),
        align: 'start',
        sortable: false,
        value: 'description',
      },
      {
        text: this.$t('colZoneEmail'),
        align: 'start',
        sortable: false,
        value: 'email',
      },
      {
        text: this.$t('colZoneIsInTest'),
        align: 'start',
        sortable: false,
        value: 'isInTest',
      },
      {
        text: this.$t('colZoneCallListNumber'),
        align: 'start',
        sortable: false,
        value: 'callListNumber',
      },
    ];
  }

  public get headersTraffic(): any[] {
    return [
      {
        text: this.$t('colTrafficMessage'),
        align: 'start',
        sortable: false,
        value: 'message',
      },
      {
        text: this.$t('colTrafficSignal'),
        align: 'start',
        sortable: false,
        value: 'signal',
      },
      {
        text: this.$t('colTrafficDate'),
        align: 'start',
        sortable: false,
        value: 'date',
      },
      {
        text: this.$t('colTrafficTime'),
        align: 'start',
        sortable: false,
        value: 'time',
      },
      {
        text: this.$t('colTrafficZone'),
        align: 'start',
        sortable: false,
        value: 'zone',
      },
    ];
  }
  public get headersAudit(): any[] {
    return [
      {
        text: this.$t('colAuditMessage'),
        align: 'start',
        sortable: false,
        value: 'message',
      },
      {
        text: this.$t('colAuditSignal'),
        align: 'start',
        sortable: false,
        value: 'signal',
      },
      {
        text: this.$t('colAuditDate'),
        align: 'start',
        sortable: false,
        value: 'date',
      },
      {
        text: this.$t('colAuditTime'),
        align: 'start',
        sortable: false,
        value: 'time',
      },
      {
        text: this.$t('colAuditZone'),
        align: 'start',
        sortable: false,
        value: 'zone',
      },
    ];
  }
}
