import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import AuthService from './AuthService';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Toasted from 'vue-toasted';
import './assets/main.css';

// eslint-disable-next-line
import Vuetify from 'vuetify';

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(Toasted, {
  duration: 2000,
});

Vue.axios.defaults.validateStatus = null;

AuthService.initialize().then(() => {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
  }).$mount('#app');
});
