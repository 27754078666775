import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import * as Cookie from 'tiny-cookie';

Vue.use(Vuetify);

let themeName = Cookie.get('theme-name');
if (themeName === null) themeName = 'light';
const themeColorstring = Cookie.get('theme-colors');
let themeColors = {};
if (themeColorstring !== null) themeColors = JSON.parse(themeColorstring);

export default new Vuetify({
  theme: {
    dark: themeName === 'dark',
    themes: {
      light: themeColors,
      dark: themeColors,
    },
  },
});
