














































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import AuthService from './AuthService';
import * as Cookie from 'tiny-cookie';

@Component({})
export default class App extends Vue {
  public changePasswordDialog: boolean = false;
  public changePasswordDialogCurrentPassword: string = '';
  public changePasswordDialogNewPassword: string = '';
  public changePasswordDialogNewPasswordVerification: string = '';

  public brandingName(): string {
    const brandName = Cookie.get('brand-name');
    if (brandName === null) return '';
    else return ' - ' + brandName;
  }

  public openChangePasswordDialog(): void {
    this.changePasswordDialogCurrentPassword = '';
    this.changePasswordDialogNewPassword = '';
    this.changePasswordDialogNewPasswordVerification = '';
    this.changePasswordDialog = true;
  }

  public async changePasswordDialogSubmit(): Promise<void> {
    if (this.changePasswordDialogCurrentPassword === '') return;
    if (this.changePasswordDialogNewPassword !== this.changePasswordDialogNewPasswordVerification) return;
    if (this.changePasswordDialogNewPassword === '') return;
    const result = await Vue.axios.post('/user/changePassword',
      {
        currentPassword: this.changePasswordDialogCurrentPassword,
        newPassword: this.changePasswordDialogNewPassword,
      });
    if (result.status !== 200) {
      Vue.toasted.error(this.$t('serverError') as string);
      return;
    }

    this.changePasswordDialogCurrentPassword = '';
    this.changePasswordDialogNewPassword = '';
    this.changePasswordDialogNewPasswordVerification = '';

    Vue.toasted.success(this.$t('serverSuccess') as string);
    this.changePasswordDialog = false;
  }

  public getLogoUrl(): string {
    if (this.$store.state.dealerLogoUrl !== undefined && this.$store.state.dealerLogoUrl !== null) {
      return this.$store.state.dealerLogoUrl;
    }

    const themeLogo = Cookie.get('theme-logo');
    if (themeLogo !== null) return themeLogo;

    return '/img/logo-clear.png';
  }

  public beforeMount(): void {
    this.$store.commit('changeLocale', this.$i18n.locale);
  }

  public get DealerRoute(): any {
    if (this.$route.name !== 'dealer') {
      return { name: 'dealer', params: { id: 'X' } };
    }
    return { name: 'dealer', params: { id: this.$route.params.id } };
  }
  public get SiteDealerRoute(): any {
    if (this.$route.name !== 'site') {
      return { name: 'dealer', params: { id: 'X' } };
    }
    if (this.$store.state.currentSite !== null && this.$store.state.currentSite !== undefined) {
      return { name: 'dealer', params: { id: this.$store.state.currentSite.site.dealerAccountNumber } };
    }
    return { name: 'dealer', params: { id: 'X' } };
  }
  public get SiteRoute(): any {
    if (this.$route.name !== 'site') {
      return { name: 'site', params: { id: 'X' } };
    }
    return { name: 'site', params: { id: this.$route.params.id } };
  }

  public changeLanguage(locale: string): void {
    window.localStorage.setItem('i18n-locale', locale);
    this.$root.$i18n.locale = locale;
    this.$store.commit('changeLocale', locale);
  }

  public async signOut(): Promise<void> {
    this.$store.commit('signOut');
    await AuthService.signOut();
  }
}
