import { UserManager, Log, WebStorageStateStore, User } from 'oidc-client';
import store from '@/store';
import Vue from 'vue';

Log.level = process.env.NODE_ENV === 'production' ? Log.INFO : Log.INFO;
Log.logger = console;

let initialized = false;

// todoerik:
// manage silent + popup auth
// ensure silent_renew works
// ensure signout is called if token expires during usage
// bring more stuff from clientAppReact

export default new class AuthService {
  private userManager!: UserManager;
  private initialized: boolean = false;

  public async initialize(): Promise<void> {
    const response = await fetch('/_configuration/lanvac.net');
    if (!response.ok) {
      throw new Error('Could not load oidc settings');
    }

    const settingsWS = await response.json();

    const settings = {
      authority: settingsWS.authority,
      client_id: settingsWS.client_id,
      redirect_uri: settingsWS.redirect_uri,
      post_logout_redirect_uri: settingsWS.post_logout_redirect_uri,
      response_type: settingsWS.response_type,
      scope: settingsWS.scope,

      automaticSilentRenew: true,
      includeIdTokenInSilentRenew: true,
      userStore: new WebStorageStateStore({
        store: window.sessionStorage,
        prefix: 'lanvacNetAuth',
      }),
    };

    this.userManager = new UserManager(settings);

    this.userManager.events.addAccessTokenExpired(function() {
      store.commit('signOut', {});
      if (initialized) {
        window.location.reload();
      }
    });

    // are we currently logged in ?
    const user = await this.getUser();
    if (user !== null) {
      if (!user.expired) {
        try {
          await store.dispatch('doSignIn', user);
        } catch {
          store.commit('signOut', {});
          await this.userManager.removeUser();
        }
      } else {
        store.commit('signOut', {});
        await this.userManager.removeUser();
      }
    }

    initialized = true;
  }

  public async getUser(): Promise<User | null> {
    const user = await this.userManager.getUser();
    return user;
  }

  public async signIn(userName: string, password: string, otp: string): Promise<undefined | string> {
    const result = await Vue.axios.post('/auth/signin', {
      userName: userName,
      password: password,
      otp: otp,
      version: store.state.buildNumber,
    });

    if (result.status === 401) {
      return result.data;
    }

    if (result.status === 200) {
      await this.userManager.signinRedirect();
      return undefined;
    }
  }

  public async signOut(): Promise<void> {
    store.commit('signOut', {});
    return this.userManager.signoutRedirect();
  }

  public async getAccessToken(): Promise<string> {
    const user = await this.getUser();
    if (!user) return '';
    return user.access_token;
  }

  public async callbackSignInRedirect(path: string): Promise<User> {
    const user = await this.userManager.signinRedirectCallback(path);
    await store.dispatch('doSignIn', user);

    return user;
  }

  public async callbackSignInSilent(path: string): Promise<User | undefined> {
    const user = await this.userManager.signinSilentCallback(path);
    if (user) {
      await store.dispatch('doSignIn', user);
    } else {
      store.commit('signOut', {});
      await this.userManager.removeUser();
    }
    return user;
  }
}();
