import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';
import Store from '@/store';
import Error from '../views/Error.vue';
import AllSites from '../views/AllSites.vue';
import AllDealers from '../views/AllDealers.vue';
import MySites from '../views/MySites.vue';
import MyDealerSites from '../views/MyDealerSites.vue';
import Dealer from '../views/Dealer.vue';
import Site from '../views/Site.vue';
import SignIn from '../views/SignIn.vue';
import AuthService from '@/AuthService';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
  },
  {
    path: '/ErrorApp',
    name: 'error',
    component: Error,
  },
  {
    path: '/sign-in',
    name: 'signIn',
    component: SignIn,
    meta: { noAuthRequired: true },
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "other" */ '../views/About.vue'),
    meta: { noAuthRequired: true },
  },
  {
    path: '/branding',
    name: 'branding',
    component: () => import(/* webpackChunkName: "other" */ '../views/Branding.vue'),
  },
  {
    path: '/authentication/login-callback',
    name: 'sign-in-callback',
  },
  {
    path: '/authentication/login-silent-callback',
    name: 'sign-in-silent-callback',
  },
  {
    path: '/authentication/logout-callback',
    name: 'sign-out-callback',
  },
  {
    path: '/allSites',
    name: 'allSites',
    component: AllSites,
  },
  {
    path: '/allDealers',
    name: 'allDealers',
    component: AllDealers,
  },
  {
    path: '/mySites',
    name: 'mySites',
    component: MySites,
  },
  {
    path: '/myDealerSites',
    name: 'myDealerSites',
    component: MyDealerSites,
  },
  {
    path: '/dealer/:id',
    name: 'dealer',
    component: Dealer,
  },
  {
    path: '/site/:id',
    name: 'site',
    component: Site,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to: Route, from: Route, next: any) => {
  if (to.name === 'sign-in-callback') { // oidc callback
    await AuthService.callbackSignInRedirect(to.fullPath);
    if (Store.state.signedIn) {
      if (Store.getters.isInRoleLANVAC) next({ name: 'allDealers' });
      else if (Store.getters.isInRoleDEALER) next({ name: 'myDealerSites' });
      else if (Store.getters.isInRoleCLIENT) next({ name: 'mySites' });
      else next({ name: 'error' });
    } else next({ name: 'error' });
  } else if (to.name === 'sign-in-silent-callback') { // oidc callback
    await AuthService.callbackSignInSilent(to.fullPath);
    next({ name: 'error' });
  } else if (to.name === 'sign-out-callback') { // oidc callback
    next({ name: 'signIn' });
  } else if (to.name === 'home' && Store.state.signedIn === true) {
    if (Store.getters.isInRoleLANVAC) next({ name: 'allDealers' });
    else if (Store.getters.isInRoleDEALER) next({ name: 'myDealerSites' });
    else if (Store.getters.isInRoleCLIENT) next({ name: 'mySites' });
    else next({ name: 'error' });
  } else if (to.meta.noAuthRequired === true) {
    next();
  } else if (Store.state.signedIn === false) {
    next({ name: 'signIn', query: { redirect: to.fullPath } });
  } else if (Store.state.signedIn === true) {
    next();
  } else {
    throw new Error();
  }
});

export default router;
