


























































import { Component, Vue } from 'vue-property-decorator';
import AuthService from '../AuthService';

@Component({})
export default class SignIn extends Vue {
  public userName: string = '';
  public password: string = '';
  public otp: string = '';
  public signInError: boolean = false;
  public loading: boolean = false;

  public async signIn(): Promise<void> {
    if (!this.canSignIn) return;
    this.doSignIn(this.userName, this.password, this.otp);
  }

  public async signInLanvac(): Promise<void> {
    this.doSignIn(process.env.NODE_ENV === 'production' ? '' : '10499', process.env.NODE_ENV === 'production' ? '' : 'ALARM2000', '');
  }

  public async signInDealer(): Promise<void> {
    this.doSignIn(process.env.NODE_ENV === 'production' ? '' : '10759', process.env.NODE_ENV === 'production' ? '' : 'ALARM2000', '');
  }

  public async signInClient(): Promise<void> {
    this.doSignIn(process.env.NODE_ENV === 'production' ? '' : '145555', process.env.NODE_ENV === 'production' ? '' : 'JSJS', '');
  }

  private async doSignIn(userName: string, password: string, otp: string): Promise<void> {
    this.$store.commit('signOut');
    try {
      const result = await AuthService.signIn(userName, password, otp);
      if (result !== undefined) {
        if (result === 'BADVERSION') {
          this.loading = true;
          Vue.toasted.info(this.$t('pleaseWait') as string, {
            duration: 10000,
          });
          window.setTimeout(async () => { window.location.reload(); }, 10000);
        } else {
          this.password = '';
          this.otp = '';
          this.signInError = true;
        }
      }
    } catch (err: any) {
      this.signInError = true;
      this.password = '';
      this.otp = '';
    }
  }

  public get isDev(): boolean {
    return process.env.NODE_ENV !== 'production';
  }

  public get needsOTP(): boolean {
    return this.userName === '10499';
  }

  public get canSignIn(): boolean {
    if (this.needsOTP && this.otp === '') return false;
    return this.userName !== '' && this.password !== '';
  }
}
